import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "@components/Layout";
import { Section } from "@components/StyledComponents";

const NotFoundPage = ({ data }) => {
  const header = data.header.childImageSharp.fluid;
  return (
    <Layout
      seoTitle="Page Not Found"
      seoDescription="404 Page Not Found"
      fluid={header}
      alt="404 Not Found"
      headerTitle="Buy.Sell.Invest"
      headerSubTitle="Your home is my commitment"
    >
      <Section>
        <p>
          Sorry{" "}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{" "}
          we couldn’t find what you were looking for.
          <br />
          <br />
          <Link to="/">Go Home</Link>.
        </p>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query NotFoundQuery {
    header: file(relativePath: { eq: "unsplash_2_1200x800.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default NotFoundPage;
